import { PAYMENT_STATUS } from "@/assets/interfaces";
import { paramsFromObject } from "../assets/js/utils/functions";
import {
  CancelPaymentParams,
  ChangePlanParams,
  CreatePaymentParams,
  CreateTestPaymentParams,
  GetPaymentAnalyticsParams,
  GetPaymentCouponParams,
  GetPaymentHistoryParams,
  GetPaymentMethodParams,
  GetPaymentMethodsParams,
  GetPlansParams,
  InitiateMoMoPaymentParams,
  InitiatePaymentParams,
  LinkCustomerToPaymentParams,
  LinkInvoiceToPaymentsParams,
  VerifyPaymentCouponParams,
  VerifyPaymentParams,
} from "./interfaces/payments.interface";
import { request } from "./utils";

const CreatePayment = (data: CreatePaymentParams) => {
  return request("payments", "post", { data });
};

const CreateTestPayment = (data: CreateTestPaymentParams) => {
  return request("payments/test-payment", "post", { data });
};

const InitiatePayment = (data: InitiatePaymentParams) => {
  return request("payments/initiate", "post", { data });
};

const VerifyPayment = (data: VerifyPaymentParams) => {
  return request(`payments/verify/${data.reference}`, "get");
};

const GetPayments = () => {
  return request("payments/subscriptions", "get");
};

const CheckUserCards = () => {
  return request("payments/check-user-cards", "get");
};

const GetPaymentMethod = (data: GetPaymentMethodParams) => {
  return request(`payment-methods/type/${data.type}`, "get");
};

const GetPaymentMethods = (data: GetPaymentMethodsParams) => {
  const params = paramsFromObject(data);
  return request(`payments/payment-methods?${params}`, "get");
};

const InitiateMoMoPayment = (data: InitiateMoMoPaymentParams) => {
  return request("payments/zeepay-debit-wallet", "post", { data });
};

const VerifyPaymentCoupon = (data: VerifyPaymentCouponParams) => {
  return request(`payment-coupons/verify`, "post", { data });
};
const GetPaymentCoupon = (data: GetPaymentCouponParams) => {
  return request(`payment-coupons/${data.coupon_code}?currency=${data.currency}`, "get");
};

const CancelPayment = (data: CancelPaymentParams) => {
  return request(`payments/${data.reference}/cancel`, "put", {});
};

//Dashboard
const GetPaymentAnalytics = (filter: GetPaymentAnalyticsParams) => {
  const params = paramsFromObject(filter);

  return request(`payments/analytics?${params}`, "get");
};

const GetInvoicePayments = (data: GetPaymentHistoryParams) => {
  const params = paramsFromObject(data);
  return request(`payments/history?${params}`, "get");
};

const LinkCustomerToPayments = (data: LinkCustomerToPaymentParams) => {
  return request(`payments/${data.reference}/link-customer/${data.customer_id}`, "put", {});
};

const LinkInvoiceToPayments = (data: LinkInvoiceToPaymentsParams) => {
  return request(`payments/${data.reference}/link-invoice/${data.invoice_id}`, "put", {});
};

const GetRecentPayments = () => {
  return request(`payments/recent-payments`, "get");
};

export {
  CreatePayment,
  VerifyPayment,
  GetPayments,
  GetPaymentMethod,
  CheckUserCards,
  GetPaymentMethods,
  InitiatePayment,
  VerifyPaymentCoupon,
  CancelPayment,
  GetPaymentCoupon,
  GetPaymentAnalytics,
  GetInvoicePayments,
  LinkCustomerToPayments,
  InitiateMoMoPayment,
  GetRecentPayments,
  LinkInvoiceToPayments,
  CreateTestPayment,
};
