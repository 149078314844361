import { FormikProps } from "formik";
import React from "react";
import {
  CURRENCIES,
  CartItem,
  CountryInterface,
  Image as ImageTyoe,
  ProductItemInterface,
  StoreInterface,
} from "../../../assets/interfaces";
import { iconColors } from "./icon-colors";
import handleImageSelectionFromFile, { generateKey, handleImageResizeOnCanvas } from "./image-selection";
import { error } from "console";
import { ParsedUrlQuery } from "querystring";

declare global {
  interface Window {
    COUNTRY: CountryInterface;
    userCountry: string;
    CURRENCY: CURRENCIES;
    CONVERSION_RATES: { [key: string]: number };
    CONVERSION_MARKUPS: { [key: string]: number };
  }
}

const getStyles = (styles) => {
  return styles as React.CSSProperties;
};

//set the destination scroll left position
function scrollFunction(e, sc, eAmt, start) {
  e.scrollLeft = eAmt * sc + start;
}

//incrementally slide the slider mimicing smoothscroll
function SmoothHorizontalScrolling(e: HTMLElement, time: number, amount: number, start: number) {
  const eAmt = amount / 100;
  let curTime = 0;
  let scrollCounter = 0;
  while (curTime <= time) {
    window.setTimeout(scrollFunction, curTime, e, scrollCounter, eAmt, start);
    curTime += time / 100;
    scrollCounter++;
  }
}

function paramsToArray(params: any) {
  const array: any[] = [];
  for (let param in params) {
    if (typeof params[param] === "object") {
      array.push(JSON.stringify(params[param]));
    } else {
      array.push(params[param]);
    }
  }
  return array;
}

function getFieldvalues(name: string, form: FormikProps<any>, type: "text" | "number" = "text", remove?: RegExp) {
  const fromDotNotation = (place) => {
    if (place[name]) return place[name];
    if (name.includes(".")) {
      const parts = name.split(".");

      return parts.reduce((acc, part) => {
        return acc?.[part];
      }, place);
    }
  };

  return {
    name,
    value: fromDotNotation(form.values),
    onChange:
      type === "text"
        ? (e: React.ChangeEvent<any>) => {
            if (remove) {
              e.target.value = e.target?.value.replace(remove, "");
            }

            form.handleChange(e);
          }
        : (e: React.ChangeEvent<HTMLInputElement>) =>
            form.setFieldValue(name, Number.isNaN(Number(e.target?.value)) ? 0 : Number(e.target?.value)),
    onBlur: form.handleBlur,
    error: fromDotNotation(form.touched) && fromDotNotation(form.errors) ? fromDotNotation(form.errors) : null,
    "data-has-error": fromDotNotation(form.touched) && fromDotNotation(form.errors) !== undefined,
    errors: fromDotNotation(form.errors),
    touched: fromDotNotation(form.touched),
  };
}

// str.slice(0, -1)
const copyToClipboard = (str: string) => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  const selected = (document.getSelection()?.rangeCount ?? 0) > 0 ? document.getSelection()?.getRangeAt(0) : false;
  el.select();
  const successful = document.execCommand("copy");
  document.body.removeChild(el);
  if (selected) {
    document.getSelection()?.removeAllRanges();
    document.getSelection()?.addRange(selected);
  }

  return successful;
};

function getAvatarBg(name: string = "") {
  // const char = name.charAt(0).toLowerCase();
  // const characters = "0123456789abcdefghijklmnopqrstuvwxyz";

  // const index = characters.indexOf(char);

  // return iconColors[index] ?? iconColors[37];

  const char = name.charAt(0).toLowerCase();

  switch (true) {
    case char >= "a" && char <= "c":
      return "bg-accent-yellow-500";
    case char >= "d" && char <= "f":
      return "bg-primary-500";
    case char >= "g" && char <= "i":
      return "bg-accent-green-500";
    case char >= "j" && char <= "l":
      return "bg-accent-orange-500";
    case char >= "m" && char <= "o":
      return "bg-accent-red-500";
    case char >= "p" && char <= "r":
      return "bg-black";
    case char >= "s" && char <= "u":
      return "bg-accent-red-500";
    case char >= "v" && char <= "z":
      return "bg-purple-600";
    default:
      return "bg-gray-600";
  }
}

const arrayToInputValues = (arr: string[]) => arr.map((val) => ({ text: val, value: val }));

const paramsFromObject = (payload: any) => {
  const params = new URLSearchParams();
  Object.keys(payload).map((key) => {
    if (payload[key]) {
      if (typeof payload[key] === "object") {
        Object.keys(payload[key]).map((key2) => {
          params.set(`${key}[${key2}]`, payload[key][key2]);
        });
      } else {
        params.set(key, payload[key]);
      }
    }
  });
  return params;
};

const getUserCountry = () => {
  if (typeof window !== "undefined" && window.COUNTRY !== undefined) return window.COUNTRY;

  return {
    name: "Nigeria",
    currency: "NGN",
    code: "NG",
    dial_code: "+234",
    emoji: "🇳🇬",
  };
};

const getProductsCurrency = () => {
  return window?.CURRENCY ?? CURRENCIES.NGN;
};

// const toCurrency = (amount, currency?: string, convert: boolean = true, decimals?: number) => {
//   const conversionRate = convert ? window?.CONVERSION_RATES[currency] ?? 1 : 1;
//   const conversionMarkup = window?.CONVERSION_MARKUPS[currency] ?? 0;

//   return `${currency || window.CURRENCY || "NGN"} ${amountFormat(amount * conversionRate, decimals)}`;
// };

const toCurrency = (
  amount: number | string,
  currency?: string,
  convert: boolean = false,
  decimals: number = 2 // Default to 2 decimal places
): string => {
  const activeCurrency = currency || window.CURRENCY || "NGN";
  const conversionRate = convert ? window?.CONVERSION_RATES[activeCurrency] ?? 1 : 1;
  const conversionMarkup = convert ? window?.CONVERSION_MARKUPS[activeCurrency] ?? 0 : 0;

  if (isNaN(Number(amount))) {
    return "";
  }

  const scale = Math.pow(10, decimals);

  // Apply markup and conversion rate with precision
  const amountWithMarkup = Math.round(Number(amount) * (1 + conversionMarkup / 100) * scale);
  const convertedAmount = Math.round(amountWithMarkup * conversionRate) / scale;

  return `${activeCurrency} ${amountFormat(convertedAmount, decimals)}`;
};

const convertAmount = (
  amount: number,
  currency?: string,
  decimals: number = 2 // Default to 2 decimal places
): number => {
  const activeCurrency = currency || window.CURRENCY || "NGN";
  const conversionRate = currency && currency !== window.CURRENCY ? window?.CONVERSION_RATES[activeCurrency] ?? 1 : 1;
  const conversionMarkup =
    currency && currency !== window.CURRENCY ? window?.CONVERSION_MARKUPS[activeCurrency] ?? 0 : 0;

  const scale = Math.pow(10, decimals);

  // Apply markup and conversion rate with precision
  const amountWithMarkup = Math.round(amount * (1 + conversionMarkup / 100) * scale);
  return Math.round(amountWithMarkup * conversionRate) / scale;
};

// const convertAmount = (amount, currency?: string) => {
//   const conversionRate = currency && currency !== window.CURRENCY ? window?.CONVERSION_RATES[currency] ?? 1 : 1;
//   const conversionMarkup = currency && currency !== window.CURRENCY ? window?.CONVERSION_MARKUPS[currency] ?? 1 : 1;

//   return amount * conversionRate;
// };

export const amountFormat = (amount: number | string, toFixed: number = 2): string => {
  const numericAmount = parseFloat(amount.toString());

  if (isNaN(numericAmount)) {
    // throw new Error("Invalid amount provided");
    return "";
  }

  const fixedAmount = numericAmount.toFixed(toFixed);
  const [integerPart, decimalPart] = fixedAmount.split(".");
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
};

export function on<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T["addEventListener"]> | [string, Function | null, ...any]
): void {
  if (obj && obj.addEventListener) {
    obj.addEventListener(...(args as Parameters<HTMLElement["addEventListener"]>));
  }
}

export function off<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T["removeEventListener"]> | [string, Function | null, ...any]
): void {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(...(args as Parameters<HTMLElement["removeEventListener"]>));
  }
}

const sluggify = (str: string) => {
  return str
    .replace(/[^\w\s]/gi, "")
    .trim()
    .toLocaleLowerCase()
    .split(" ")
    .join("-");
};

function getRandString(length) {
  let result = "";
  let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export const reloadPage = () => {
  window.location.reload();
};

const toAppUrl = (str: string, showProtocol: boolean = true, isPublic: boolean = true) => {
  const baseUrl = isPublic ? process.env.NEXT_PUBLIC_PUBLIC_URL : process.env.NEXT_PUBLIC_APP_URL;
  let url = `${baseUrl}/${str}`;

  if (!showProtocol) {
    url = url.split("//")[1];
  }

  return url;
};

const subdomainStoreLink = (
  slug: string,
  withProtocol: boolean = false,
  type: "store" | "chowbot" = "store",
  searchParams?: string
) => {
  const baseUrls = {
    chowbot: process.env.NEXT_PUBLIC_CHOWBOT_URL,
    store: process.env.NEXT_PUBLIC_PUBLIC_URL,
  };

  if (process.env.NODE_ENV !== "production") {
    return `${baseUrls[type]?.replace(/^https:\/\//, "")}/${slug}${searchParams ? `?${searchParams}` : ""}`;
  }

  return `${withProtocol ? "https://" : ""}${slug}.${baseUrls[type].replace(/^https?:\/\//, "")}${
    searchParams ? `?${searchParams}` : ""
  }`;
};

const defaultDateOptions: any = {
  month: "short",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "2-digit",
  hourCycle: "h12",
};

function formatDate(date: string, options = defaultDateOptions) {
  try {
    const shortEnNGFormatter = new Intl.DateTimeFormat("en-GB", options);
    return shortEnNGFormatter.format(new Date(date));
  } catch (e) {
    return "N/A";
  }
}

function formatDateTime(date: string, options = defaultDateOptions) {
  try {
    const d = formatDate(date, options).split(",");
    const datetime = d[0] + "," + d[1].toUpperCase();
    return datetime;
  } catch (e) {
    return "N/A";
  }
}

function humanFriendlyDate(date, addTime = false) {
  date = new Date(date);
  // Define the suffixes for date ordinal indicators
  const suffixes = ["th", "st", "nd", "rd"];

  // Get the day, month, and year from the input date
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  // Get the suffix for the day
  const daySuffix =
    suffixes[
      day % 10 === 1 && day !== 11 ? 1 : day % 10 === 2 && day !== 12 ? 2 : day % 10 === 3 && day !== 13 ? 3 : 0
    ];

  // Get the short form of the month
  const monthNamesShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const monthNameShort = monthNamesShort[monthIndex];

  // Combine the day, suffix, short month name, and year to form the final string
  let dateString = `${day}${daySuffix}, ${monthNameShort} ${year}`;

  if (!addTime) return dateString;

  // Get the 12-hour time format with AM/PM
  let hours = date.getHours();
  const amPM = hours >= 12 ? "pm" : "am";
  hours = hours % 12 || 12; // Convert to 12-hour format
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Combine the day, suffix, short month name, year, hours, and minutes to form the final string
  dateString = `${dateString} ${hours}:${minutes}${amPM}`;

  return dateString;
}

const hyphenateDates = (date: Date) => {
  if (!date) return "";

  return date.toISOString().split("T")[0];
};

const getStoreFrontSEOTags = (slug: string, store: StoreInterface) => {
  if (!store) {
    return {
      title: "Not found - Catlog",
      description: "Store was not found.",
      pageUrl: `/${slug}`,
      image: "https://res.cloudinary.com/lunoidapp/image/upload/v1635069576/default-store-seo-banner_yymkcu.jpg",
    };
  } else {
    return {
      title: `${store.name} - Catlog`,
      description: store.description,
      pageUrl: `/${slug}`,
      image:
        store.logo ||
        "https://res.cloudinary.com/lunoidapp/image/upload/v1635069576/default-store-seo-banner_yymkcu.jpg",
    };
  }
};
const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const timeToClock = (time: number) => {
  const minutes = Math.floor(time / 60000);
  const seconds = Math.floor((time % 60000) / 1000);

  return `${minutes}mins ${seconds}secs`;
};

function checkOverflow(el) {
  if (!el) {
    return false;
  }

  var curOverflow = el.style.overflow;

  if (!curOverflow || curOverflow === "visible") el.style.overflow = "hidden";

  var isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

  el.style.overflow = curOverflow;

  return isOverflowing;
}

function millify(num: number, fractionDigits: number = 0) {
  if (!num) return 0;

  // if (num % 1 === 0) {
  //   fractionDigits = 0;
  // }

  function getStr() {
    if (num > 999 && num < 1_000_000) {
      return (num / 1000).toFixed(num % 1000 > 0 ? fractionDigits : 0) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num >= 1_000_000 && num < 1_000_000_000) {
      return (num / 1_000_000).toFixed(fractionDigits) + "M"; // convert to M for number from > 1 million
    } else if (num >= 1_000_000_000) {
      return (num / 1_000_000_000).toFixed(fractionDigits) + "B"; // convert to B for number from > 1 billion
    } else if (num < 1000) {
      return num.toFixed(fractionDigits); // if value < 1000, nothing to do
    }
  }

  switch (Math.sign(num)) {
    case -1:
      num = Math.abs(num);
      return "-" + getStr();
    case 1:
      return getStr();
    default:
      return getStr();
  }
}

const removeCountryCode = (phone: string) => {
  const splitPhone = phone.split("-");

  if (splitPhone.length > 1) {
    return `0${splitPhone[1]}`;
  }
  return phone;
};

const genarateStringFromVariantValues = (values: { [key: string]: string }) => {
  let string = "";

  if (!values) {
    return "-";
  }

  Object.values(values).forEach((val, index) => {
    string += index === 0 ? val : ` - ${val}`;
  });

  return string;
};

const hasDuplicates = (array: string[]) => {
  var valuesSoFar = Object.create(null);
  for (var i = 0; i < array.length; ++i) {
    var value = array[i];
    if (value in valuesSoFar) {
      return true;
    }
    valuesSoFar[value] = true;
  }
  return false;
};

const getVariantFromItemsList = (i: CartItem) => {
  return i?.variant_id ? i.object?.variants?.options.find((v) => v.id === i.variant_id) : null;
};

const removeUnderscores = (str: string) => str.replace(/_/g, " ");

const generateHoursInterval = (startHourInMinute, endHourInMinute, interval) => {
  const times = [];

  for (let i = 0; startHourInMinute < 24 * 60; i++) {
    if (startHourInMinute > endHourInMinute) break;

    var hh = Math.floor(startHourInMinute / 60); // getting hours of day in 0-24 format

    var mm = startHourInMinute % 60; // getting minutes of the hour in 0-55 format

    const AMPM = hh >= 12 ? "PM" : "AM";
    const hour = hh % 12 === 0 ? 12 : hh % 12;

    times[i] = ("0" + hour).slice(-2) + ":" + ("0" + mm).slice(-2) + AMPM;

    startHourInMinute = startHourInMinute + interval;
  }

  return times;
};

function shuffleArray<T>(array: T[]) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}
const delay = async (time: number): Promise<void> => {
  return new Promise((resolve) => {
    const id = setTimeout(() => {
      resolve();
      clearTimeout(id);
    }, time);
  });
};

const phoneObjectFromString = (phone: string) => {
  const phoneSplit = phone?.split("-");

  if (!phoneSplit || phoneSplit?.length < 1) {
    return {
      code: "+234",
      digits: "",
    };
  }

  return {
    code: phoneSplit?.length > 1 ? phoneSplit[0] : "+234",
    digits: phoneSplit?.length > 1 ? phoneSplit[1] : phoneSplit[0],
  };
};

const phoneObjectToString = (phone: { code: string; digits: string }) => `${phone.code}-${phone.digits}`;
const toBase64 = (string) => Buffer.from(string).toString("base64");
const fromBase64 = (string) => Buffer.from(string, "base64").toString();

function dateToDDMMYYYY(date: Date) {
  if (!date) return "";

  return (
    (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
    "-" +
    (date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1)) +
    "-" +
    date.getFullYear()
  );
}

function ddMMYYYYToDate(date: string) {
  const dateParts = date.split("-");

  if (dateParts.length < 3) return null;

  // month is 0-based, that's why we need dataParts[1] - 1
  return new Date(+dateParts[2], Number(dateParts[1]) - 1, +dateParts[0]);
}

function resolvePhone(phone: string) {
  if (phone?.startsWith("0")) {
    return phone?.replace("0", "234");
  }

  return phone?.replace("-", "");
}

const randomItemFromArray = (array: any[]) => {
  return array[Math.floor(Math.random() * array.length)];
};

// function convertDotNotationToObject(obj) {
//   const result = {};

//   for (const key in obj) {
//     const keys = key.split(".");
//     keys.reduce((nestedObj, nestedKey, index) => {
//       return (nestedObj[nestedKey] = index === keys.length - 1 ? obj[key] : nestedObj[nestedKey] || {});
//     }, result);
//   }

//   return result;
// }

function convertDotNotationToObject(obj) {
  const result = {};

  for (const key in obj) {
    const keys = key.split(".");
    let current = obj[key];
    let target = result;

    for (let i = 0; i < keys.length; i++) {
      const keyPart = keys[i];
      const matches = keyPart.match(/^(\w+)(?:\[(\d+)\])?$/);

      if (matches) {
        const [, prop, index] = matches;
        if (index !== undefined) {
          target[prop] = target[prop] || [];
          target = target[prop];
          target[index] = i === keys.length - 1 ? current : target[index] || {};
        } else {
          target[prop] = i === keys.length - 1 ? current : target[prop] || {};
          target = target[prop];
        }
      }
    }
  }

  return result;
}

async function getImageObjectFromUrl(src: string, key: string, url?: string, meta?: any): Promise<ImageTyoe> {
  const isUploaded = url !== undefined;
  let blob: Blob;
  if (!isUploaded) blob = await fetchBlob(src);
  return {
    src,
    name: getRandString(10),
    lastModified: Date.now(),
    file: blob,
    isUploading: false,
    uploadProgress: isUploaded ? 100 : 0,
    url: url ?? "",
    key,
    meta,
  };
}

async function resizeImageAndGetObjectFromUrl(src: string, key: string, url?: string, meta?: any): Promise<ImageTyoe> {
  const isUploaded = url !== undefined;
  let blob: Blob;
  if (!isUploaded) {
    blob = await fetchBlob(src);

    const blobURL = URL.createObjectURL(blob);

    const img = new Image();
    img.src = blobURL;

    img.addEventListener("error", () => {
      URL.revokeObjectURL(blobURL);
      // Handle the failure properly - come back to this important
      console.log("Cannot load image");
    });

    img.addEventListener("load", async () => {
      const resizedImage = await handleImageResizeOnCanvas(img, 1000, blobURL);
      return {
        src: resizedImage.url,
        name: getRandString(10),
        lastModified: Date.now(),
        file: resizedImage.blob,
        isUploading: false,
        uploadProgress: 0,
        url: "",
        key,
      };
    });
  }

  return {
    src,
    name: getRandString(10),
    lastModified: Date.now(),
    file: blob,
    isUploading: false,
    uploadProgress: isUploaded ? 100 : 0,
    url: url ?? "",
    key,
    meta,
  };
}

function serializeErrors(errors: { [key: string]: string } | string[]) {
  let errorsArray: string[] = [];

  if (typeof errors === "object" && !Array.isArray(errors) && errors !== null) {
    errorsArray = Object.values(errors);
  } else if (Array.isArray(errors)) {
    errorsArray = errors;
  }

  return errorsArray.join(",");
}

function cleanUpNumberString(number: any) {
  return Number(String(number).replace(",", ""));
}
function getRandomHexColor() {
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += Math.floor(Math.random() * 16).toString(16);
  }
  return color;
}

function trimArray<T>(arr: T[], targetLength: number) {
  return arr.slice(0, targetLength);
}

function rgbToHex(color: [number, number, number]): string {
  return "#" + color.map((c) => c.toString(16).padStart(2, "0")).join("");
}

function hexToRgb(hex: string): { r: number; g: number; b: number } {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 0, g: 0, b: 0 };
}

function isDefined(obj: any) {
  return obj !== undefined && obj !== null;
}

function isColorDarkEnoughForWhiteText(hex: string): boolean {
  const { r, g, b } = hexToRgb(hex);
  const luminance = 0.2126 * Math.pow(r / 255, 2.2) + 0.7152 * Math.pow(g / 255, 2.2) + 0.0722 * Math.pow(b / 255, 2.2);

  return luminance < 0.3;
}

// async function fetchBlob(url) {
//   try {
//     const response = await fetch(url);
//     return response.blob();
//   } catch (error) {
//     console.log("IMAGE FETCH ERROR:" + error);
//   }
// }

async function fetchBlob(url) {
  const response = await fetch(url);
  return response.blob();
}

const canSubmitForm = (form: FormikProps<any>, checkTouched = true) => {
  return checkTouched
    ? Object.values(form?.errors)?.length === 0 || Object.values(form?.touched)?.length === 0
    : Object.values(form?.errors)?.length === 0;
};

function dataURItoBlob(dataURI: string, filename: string) {
  // Split the data URI into parts
  const parts = dataURI.split(";base64,");
  if (parts.length !== 2) {
    throw new Error("Invalid data URI format");
  }

  // Get content type and base64 data
  const contentType = parts[0].split(":")[1];
  const base64Data = parts[1];

  // Decode the base64 data
  const byteString = atob(base64Data);

  // Convert the byte string to a Uint8Array
  const buffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(buffer);
  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  // Create the File object
  const blob = new Blob([uint8Array], { type: contentType });
  // return new File([blob], filename, { type: contentType });
  return blob;
}

function generateSimpleUUID() {
  // Create a timestamp as a base part of UUID
  let timeStamp = new Date().getTime();
  // Convert the timestamp to a base-36 string (using numbers and letters)
  let base36Timestamp = timeStamp.toString(36);

  // Generate random values and convert them to base-36 for extra randomness
  let randomValues = Math.random().toString(36).substring(2, 10);

  // Concatenate and slice to ensure the UUID is 16 characters long
  let uuid = (base36Timestamp + randomValues).slice(0, 16);

  return uuid;
}

function splitPhone(phone: string) {
  if (!phone.includes("-")) return ["+234", phone];

  return phone.split("-");
}
function enumToHumanFriendly(text: string): string {
  return text
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

function setNestedValue(obj: Record<string, unknown> | unknown[], path: string[], value: unknown): void {
  let current = obj;
  for (let i = 0; i < path.length; i++) {
    const key = path[i];
    const isArrayIndex = /^\d+$/.test(key);
    const isLast = i === path.length - 1;

    if (isLast) {
      // Set the value directly
      if (isArrayIndex) {
        const idx = parseInt(key, 10);
        if (!Array.isArray(current)) {
          throw new Error(`Expected an array at "${path.slice(0, i).join(".")}" but found an object.`);
        }
        current[idx] = value;
      } else {
        if (Array.isArray(current)) {
          throw new Error(`Expected an object at "${path.slice(0, i).join(".")}" but found an array.`);
        }
        current[key] = value;
      }
    } else {
      // Not the last segment, ensure structure
      if (isArrayIndex) {
        const idx = parseInt(key, 10);
        if (!Array.isArray(current)) {
          // Convert current object into array if we detect numeric indexing
          const newArr: unknown[] = [];
          Object.assign(newArr, current);
          current = newArr;
        }
        // Ensure index
        if (current[idx] === undefined) {
          current[idx] = {};
        }
        if (typeof current[idx] !== "object" || current[idx] === null) {
          // Replace non-object with empty object
          current[idx] = {};
        }
        current = current[idx] as Record<string, unknown> | unknown[];
      } else {
        if (Array.isArray(current)) {
          throw new Error(`Trying to use a non-numeric key "${key}" on an array.`);
        }
        if (current[key] === undefined) {
          current[key] = {};
        }
        if (typeof current[key] !== "object" || current[key] === null) {
          current[key] = {};
        }
        current = current[key] as Record<string, unknown> | unknown[];
      }
    }
  }
}

function queryToNestedObject(query: ParsedUrlQuery): Record<string, unknown> {
  const result: Record<string, unknown> = {};

  for (const rawKey in query) {
    if (!Object.prototype.hasOwnProperty.call(query, rawKey)) continue;
    const value = query[rawKey];

    const path = rawKey.replace(/\]$/, "").split("[");
    setNestedValue(result, path, value);
  }

  return result;
}

function computeMissingRates(rates: { [key: string]: { [key: string]: number } }, baseCurrency: CURRENCIES) {
  if (!rates || !rates[baseCurrency]) {
    return {};
  }

  let usdRate = rates[baseCurrency]["USD"];

  // Try to compute USD rate from USD->baseCurrency if not found directly
  if (!usdRate) {
    const inverseUsdRate = rates["USD"]?.[baseCurrency];
    if (!inverseUsdRate) {
      return {};
    }
    usdRate = 1 / inverseUsdRate;
    rates[baseCurrency]["USD"] = usdRate;
  }

  for (const targetCurrency in rates) {
    if (targetCurrency === baseCurrency) continue;
    if (rates[baseCurrency][targetCurrency]) continue;

    const targetRateFromUSD = rates["USD"]?.[targetCurrency];
    if (targetRateFromUSD) {
      rates[baseCurrency][targetCurrency] = usdRate * targetRateFromUSD;
    }
  }

  return rates[baseCurrency];
}

function getExchangeRates(
  allRates: { [key: string]: { [key: string]: number } },
  defaultCurrency: CURRENCIES,
  allCurrencies: CURRENCIES[]
) {
  try {
    if (allCurrencies.length > 1 || (allCurrencies.length === 1 && allCurrencies[0] !== defaultCurrency)) {
      const rates = computeMissingRates(allRates, defaultCurrency);

      return rates;
    } else {
      return { [defaultCurrency]: 1 };
    }
  } catch (error) {
    console.log("Error setting conversion rates", error);

    return null;
  }
}

export const paginateArray = (array: any[], page: number, perPage: number) => {
  const offset = (page - 1) * perPage;
  const paginatedItems = array.slice(offset, offset + perPage);
  const totalPages = Math.ceil(array.length / perPage);

  return {
    items: paginatedItems,
    totalPages,
    totalItems: array.length,
    currentPage: page,
    hasNextPage: page < totalPages,
    hasPrevPage: page > 1,
  };
};

export const splitItemsIntoPages = (items, perPage) => {
  const pages = [];
  for (let i = 0; i < items.length; i += perPage) {
    pages.push(items.slice(i, i + perPage));
  }
  return pages;
};

export const sortItems = (items: ProductItemInterface[] = []) => {
  return [...items].sort((a, b) => {
    if (a.sort_index && b.sort_index) {
      return b.sort_index - a.sort_index;
    } else if (a.sort_index) {
      return -1;
    } else if (b.sort_index) {
      return 1;
    } else {
      return 0; // No need to sort by date as it's already sorted by date from the backend
    }
  });
};

function formatTime(seconds: number): string {
  if (seconds < 60) return `${seconds}s`;

  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) return `${minutes}m`;

  const hours = Math.floor(minutes / 60);
  if (hours < 24) return `${hours}h`;

  const days = Math.floor(hours / 24);
  return `${days}d`;
}

export {
  getStyles,
  handleImageSelectionFromFile,
  SmoothHorizontalScrolling,
  paramsToArray,
  getFieldvalues,
  copyToClipboard,
  getAvatarBg,
  arrayToInputValues,
  paramsFromObject,
  toCurrency,
  sluggify,
  getRandString,
  toAppUrl,
  formatDateTime,
  getStoreFrontSEOTags,
  formatDate,
  capitalizeFirstLetter,
  timeToClock,
  checkOverflow,
  millify,
  removeCountryCode,
  genarateStringFromVariantValues,
  hasDuplicates,
  getVariantFromItemsList,
  removeUnderscores,
  generateHoursInterval,
  getUserCountry,
  delay,
  shuffleArray,
  dateToDDMMYYYY,
  ddMMYYYYToDate,
  phoneObjectFromString,
  phoneObjectToString,
  toBase64,
  fromBase64,
  resolvePhone,
  randomItemFromArray,
  getProductsCurrency,
  humanFriendlyDate,
  convertDotNotationToObject,
  convertAmount,
  getImageObjectFromUrl,
  subdomainStoreLink,
  serializeErrors,
  hyphenateDates,
  cleanUpNumberString,
  getRandomHexColor,
  trimArray,
  rgbToHex,
  isColorDarkEnoughForWhiteText,
  fetchBlob,
  canSubmitForm,
  dataURItoBlob,
  generateSimpleUUID,
  isDefined,
  splitPhone,
  resizeImageAndGetObjectFromUrl,
  enumToHumanFriendly,
  queryToNestedObject,
  computeMissingRates,
  getExchangeRates,
  formatTime,
};
