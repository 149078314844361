import { PhoneInterface } from ".";
import { CartItem } from "./carts";
import { DELIVERY_STATUSES, IDeliveryAddress } from "./deliveries";
import { INVOICE_STATUSES } from "./invoices";
import { VariantForm } from "./products";
import { CURRENCIES, DeliveryArea, StoreInterface } from "./stores";

export interface CustomerInterface {
  id: string;
  name: string;
  email?: string;
  phone: string;
  total_orders?: number;
  total_order_amount?: number;
  delivery_address?: string;
  orders?: OrderInterface[];
  store?: string;
}

export interface OrderInterface {
  id: string;
  customer: CustomerInterface;
  store: string | StoreInterface;
  status: ORDER_STATUSES;
  total_amount: number;
  discount_amount?: number;
  delivery_method?: DELIVERY_METHODS;
  delivery_info: {
    delivery_address: string;
    name?: string;
    phone?: string;
    delivery_area?: DeliveryArea;
  };
  validated_delivery_address?: IDeliveryAddress;
  items: CartItem[];
  created_at: string;
  timeline: { id: string; status: OrderStatusType; time: string }[];
  fees: { id: string; label: string; amount: number; type: ORDER_FEES }[];
  invoice?:
    | {
        id: string;
        invoice_id: string;
        status: INVOICE_STATUSES;
      }
    | string;
  receipt?: string;
  currency: CURRENCIES;
  order_notes?: string;
  delivery?: string | { id: string; status: DELIVERY_STATUSES };
  channel?: ORDER_CHANNELS;
  is_paid?: boolean;
  extra_details: { [key: string]: any };
}

export interface OrderItem {
  item_id: string;
  quantity: number;
  image?: string;
  price: number;
  name: string;
  variant_id?: string;
  variants?: VariantForm;
}
export interface OrderForm {
  items?: CartItem[];
  customer_info?: {
    id?: string;
    name?: string;
    email?: string;
    phone: PhoneInterface;
  };
  delivery_info?: {
    use_customer_info?: boolean;
    delivery_address: string;
    area?: string;
    name?: string;
    phone?: PhoneInterface;
  };
  extra_info: {
    fees?: { [type: string]: { amount?: number; meta?: any } };
    channel?: ORDER_CHANNELS;
    is_paid: boolean;
    status: ORDER_STATUSES;
  };
  delivery_method?: DELIVERY_METHODS;
}

export enum ORDER_FEES {
  DELIVERY = "DELIVERY",
  VAT = "VAT",
  OTHERS = "OTHERS",
  DISCOUNT = "DISCOUNT",
  COUPON = "COUPON",
  PAYMENT = "PAYMENT",
}

export enum DELIVERY_METHODS {
  PICKUP = "PICKUP",
  DELIVERY = "DELIVERY",
}

export enum ORDER_CHANNELS {
  PHYSICAL_STORE = "PHYSICAL_STORE",
  STORE_FRONT = "STORE_FRONT",
  INSTAGRAM = "INSTAGRAM",
  WHATSAPP = "WHATSAPP",
  TWITTER = "TWITTER",
  SNAPCHAT = "SNAPCHAT",
  OTHERS = "OTHERS",
  FACEBOOK = "FACEBOOK",
}

export enum ORDER_STATUSES {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  FULFILLED = "FULFILLED",
  CANCELLED = "CANCELLED",
  ABANDONED = "ABANDONED",
}

export type OrderStatusType = "PENDING" | "PROCESSING" | "FULFILLED" | "CANCELLED" | "ABANDONED";
