let start = 0;
export const getIntroAnimation1 = function (tl: gsap.core.Timeline, rings: any[]) {
  tl.call(() => {
    start = Date.now();
  });

  tl.to(".part-2", { display: "none", opacity: 0, duration: 0 });

  for (let i = 0; i < rings.length; i++) {
    tl.set(`.c-ring-${i}`, {
      rotate: 0 + i * 45,
      scale: 0.1,
      opacity: 0,
    });
  }

  tl.to(".c-ring", {
    scale: 2,
    duration: 1,
    opacity: 1,
    ease: "power1.out",
  });

  for (let i = 0; i < rings.length; i++) {
    tl.to(
      `.c-ring-${i}`,
      {
        rotate: 360 * 2,
        duration: 5,
        ease: "expo.out",
      },
      "<"
    );
  }

  for (let i = 0; i < 5; i++) {
    tl.to(
      `.row-${i + 1}`,
      {
        y: "0",
        delay: 0.1,
        duration: 0.5,
        ease: "power1.out",
        opacity: 1,
      },
      i == 0 ? undefined : "<"
    );
  }

  tl.to(
    `.trinkets`,
    {
      duration: 0.5,
      delay: 0.3,
      ease: "power1.out",
      opacity: 1,
    },
    "<"
  );

  for (let i = 0; i < 5; i++) {
    tl.to(
      `.row-${i + 1}`,
      {
        y: "0",
        delay: i == 0 ? 3 : 0.1,
        duration: 0.5,
        ease: "power1.out",
        opacity: 0,
      },
      i == 0 ? undefined : "<"
    );
  }

  tl.to(".part-1", { display: "none" });
};

export const getIntroAnimation2 = function (tl: gsap.core.Timeline, rings: any[], isShared?: boolean) {
  tl.to(".part-2", { display: "block", opacity: 1, duration: 0 })
    .to(".arrows", { rotate: 360 * 8, duration: 3, ease: "expo.out" })
    .to(".carret-text-1", { text: "Curious to know how", duration: 1.5, delay: 1.5 }, "<")
    .to(".carret-text-2", { text: isShared ? "my business stacks up?" : "your business stacks up?", duration: 1.6 })
    .call(() => {
      // console.log((Date.now() - start) / 1000, "seconds passed");
    });
};
